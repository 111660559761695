import axios from 'axios'
import router from '../router'
import {Message} from 'element-ui'

// 创建axios实例
const instance = axios.create({
	baseURL: 'https://api.hhsj798.xyz/Admin',
	timeout: 60000 // 设置请求超时时间
})

// 请求拦截器
instance.interceptors.request.use(
	config => {
		config.headers.Authorization = localStorage.getItem('access_token')
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

// 响应拦截器
instance.interceptors.response.use(
	response => {
		if(response.data.code === 401)
		{
			Message({
				message: '登录超时，请重新登录',
				type: 'error'
			});
			setTimeout(()=>{
					  router.push("/Login");
			}, 1000);
			return;
		}
		return response.data
	},
	error => {
		Message({
			message: error.message,
			type: 'error'
		});
		return Promise.reject(error)
	}
)

export default instance